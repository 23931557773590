import { useEffect } from "react";
import { graphql } from "gatsby";
import { dataLayerPush } from "../../utility/dataLayerUtils";

const processEvent = (rawEvent, location) =>
  Object.entries(JSON.parse(rawEvent)).reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: value
        .replace(/<URL>/, location.origin + location.pathname)
        .replace(/<RelativeURL>/, location.pathname),
    }),
    {}
  );

const DatalayerFormLanding = ({ data, location }) => {
  const { dataLayerLoad, dataLayerSubmit } = data;

  useEffect(() => {
    const dataLayerLoadEvents = dataLayerLoad?.map((item) => item?.value).filter(Boolean);
    if (dataLayerLoadEvents?.length) {
      for (const dataLayerRawEvent of dataLayerLoadEvents) {
        const dataLayerEvent = processEvent(dataLayerRawEvent, location);
        dataLayerPush(dataLayerEvent);
      }
    }
  }, [location, dataLayerLoad]);

  useEffect(() => {
    const dataLayerSubmitEvents = dataLayerSubmit?.map((item) => item?.value).filter(Boolean);
    if (dataLayerSubmitEvents?.length) {
      const listener = () => {
        for (const dataLayerRawEvent of dataLayerSubmitEvents) {
          const dataLayerEvent = processEvent(dataLayerRawEvent, location);
          dataLayerPush(dataLayerEvent);
        }
      };
      document.addEventListener("form-landing-success", listener);
      return () => document.removeEventListener("form-landing-success", listener);
    }
  }, [location, dataLayerSubmit]);
  return null;
};

export default DatalayerFormLanding;
export const fragment = graphql`
  fragment JskDatalayerFormLandingFragment on LiferayJskDatalayerFormLanding {
    liferayFields {
      siteId
      articleId
    }
    dataLayerLoad {
      value
    }
    dataLayerSubmit {
      value
    }
  }
`;

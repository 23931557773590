import React from "react";
import { graphql } from "gatsby";
import SlideHeader from "../fasce/slide-header";
import LandingHeaderComparatore from "../fasce/landing-header-comparatore";
import NewGenericLanding from "../fasce/new-generic-landing";
import CallMeBack from "../fasce/call-me-back";
import EditorialeGenerico from "../fasce/editoriale-generico";
import WidgetTestuale from "../fasce/widget-testuale";
import BottoneCtaIsolato from "../fasce/bottone-cta-isolato";
import CaratteristicheOfferta from "../fasce/caratteristiche-offerta";
import CaratteristichePuzzle from "../fasce/caratteristiche-puzzle";
import Intro from "../fasce/intro";
import AssicurazioneWidget from "../fasce/assicurazione-widget";
import ComparatoreSlideNew from "../fasce/comparatore-slide-new";
import TabBox from "../fasce/tab-box";
import Faq from "../fasce/faq";
import OpzioniProdotto from "../fasce/opzioni-prodotto";
import OpzioniProdottoV2 from "../fasce/opzioni-prodotto-v2";
import BoxOpinioni from "../fasce/box-opinioni";
import MotivazioniDocumenti from "../fasce/motivazioni-documenti";
import BadgeWidget from "../fasce/badge-widget";
import Timeline from "../fasce/timeline";
import RecensioniVerificateReviews from "../fasce/recensioni-verificate-reviews";
import Motivazioni from "../fasce/motivazioni";
import IconListParagraph from "../fasce/icon-list-paragraph";
import ScopriDipiu from "../fasce/scopri-di-piu";
import ElencoDocumenti from "../fasce/elenco-documenti";
import Editoriale from "../fasce/editoriale";
import FormContatti from "../fasce/form-contatti";
import AppBadge from "../fasce/app-badge";
import FlussoSwitchIn from "../fasce/flusso-switch-in";
import DatalayerFormLanding from "../fasce/datalayer-form-landing";
import OverlayPassword from "../fasce/overlay-password";
import FasciaMulticolonna from "../fasce/fascia-multicolonna";
import HtmlContent from "../fasce/html-content";
import EntryPointProcessiInself from "../fasce/entry-point-processi-inself";
import HubProdotto from "../fasce/hub-prodotto";

const FasciaLandingOfferte = ({ fascia, ...other }) => {
  switch (fascia.__typename) {
    case "LiferaySlideHeader":
      return <SlideHeader data={fascia} {...other} />;
    case "LiferayLandingHeaderComparatore":
      return <LandingHeaderComparatore data={fascia} {...other} />;
    case "LiferayNewGenericLanding":
      if (
        other?.location?.pathname === "/offerte/call-me-back/" ||
        other?.location?.pathname === "/offerte/call-me-back"
      )
        return <CallMeBack data={fascia} {...other} />;
      else return <NewGenericLanding data={fascia} {...other} />;
    case "LiferayEditorialeGenerico":
      return <EditorialeGenerico data={fascia} {...other} />;
    case "LiferayWidgetTestuale":
      return <WidgetTestuale data={fascia} {...other} />;
    case "LiferayBottoneCtaIsolato":
      return <BottoneCtaIsolato data={fascia} {...other} />;
    case "LiferayCaratteristicheOfferta":
      return <CaratteristicheOfferta data={fascia} {...other} />;
    case "LiferayCaratteristichePuzzle":
      return <CaratteristichePuzzle data={fascia} {...other} />;
    case "LiferayIntro":
      return <Intro data={fascia} {...other} />;
    case "LiferayAssicurazioneWidget":
      return <AssicurazioneWidget data={fascia} {...other} />;
    case "LiferayComparatoreSlideNew":
      return <ComparatoreSlideNew data={fascia} {...other} />;
    case "LiferayTabBox":
      return <TabBox data={fascia} {...other} />;
    case "LiferayFaq":
      return <Faq data={fascia} {...other} />;
    case "LiferayOpzioniProdotto":
      return <OpzioniProdotto data={fascia} {...other} />;
    case "LiferayOpzioniProdottoV2":
      return <OpzioniProdottoV2 data={fascia} {...other} />;
    case "LiferayBoxOpinioni":
      return <BoxOpinioni data={fascia} {...other} />;
    case "LiferayMotivazioniDocumenti":
      return <MotivazioniDocumenti data={fascia} {...other} />;
    case "LiferayBadgeWidget":
      return <BadgeWidget data={fascia} {...other} />;
    case "LiferayTimeline":
      return <Timeline data={fascia} {...other} />;
    case "LiferayRecensioniVerificateReviews":
      return <RecensioniVerificateReviews data={fascia} {...other} />;
    case "LiferayMotivazioni":
      return <Motivazioni data={fascia} {...other} />;
    case "LiferayIconListParagraph":
      return <IconListParagraph data={fascia} {...other} />;
    case "LiferayScopriDiPiu":
      return <ScopriDipiu data={fascia} {...other} />;
    case "LiferayElencoDocumenti":
      return <ElencoDocumenti data={fascia} {...other} />;
    case "LiferayEditoriale":
      return <Editoriale data={fascia} {...other} />;
    case "LiferayJskFormContatti":
      return <FormContatti data={fascia} {...other} />;
    case "LiferayJskAppBadge":
      return <AppBadge data={fascia} {...other} />;
    case "LiferayJskFlussoSwitchIn":
      return <FlussoSwitchIn data={fascia} {...other} />;
    case "LiferayJskDatalayerFormLanding":
      return <DatalayerFormLanding data={fascia} {...other} />;
    case "LiferayJskOverlayPassword":
      return <OverlayPassword data={fascia} {...other} />;
    case "LiferayJskFasciaMulticolonna":
      return <FasciaMulticolonna data={fascia} {...other} />;
    case "LiferayJskHtmlContent":
      return <HtmlContent data={fascia} {...other} />;
    case "LiferayJskEntryPointProcessiInself":
      return <EntryPointProcessiInself data={fascia} {...other} />;
    case "LiferayJskHubProdotto":
      return <HubProdotto data={fascia} {...other} />;
    default:
      console.warn(`Cannot handle ${fascia.__typename} type for landing offerte`);
      return null;
  }
};

export default FasciaLandingOfferte;
export const query = graphql`
  fragment FasciaLandingOfferteFragment on LiferayWebContent {
    __typename
    ...SlideHeaderFragment
    ...LandingHeaderComparatoreFragment
    ...NewGenericLandingFragment
    ...EditorialeGenericoFragment
    ...WidgetTestualeFragment
    ...BottoneCtaIsolatoFragment
    ...CaratteristicheOffertaFragment
    ...CaratteristichePuzzleFragment
    ...IntroFragment
    ...AssicurazioneWidgetFragment
    ...ComparatoreSlideNewFragment
    ...TabBoxFragment
    ...FaqFragment
    ...OpzioniProdottoFragment
    ...OpzioniProdottoV2Fragment
    ...BoxOpinioniFragment
    ...MotivazioniDocumentiFragment
    ...BadgeWidgetFragment
    ...TimelineFragment
    ...RecensioniVerificateReviewsFragment
    ...MotivazioniFragment
    ...IconListParagraphFragment
    ...ScopriDiPiuFragment
    ...ElencoDocumentiFragment
    ...EditorialeFragment
    ...FormContattiFragment
    ...JskAppBadgeFragment
    ...JskFlussoSwitchInFragment
    ...JskDatalayerFormLandingFragment
    ...JskOverlayPasswordFragment
    ...JskFasciaMulticolonnaFragment
    ...JskHtmlContentFragment
    ...JskEntryPointProcessiInselfFragment
    ...JskHubProdottoFragment
  }
`;

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaLandingOfferte from "../components/page-content/fascia-landing-offerte";
import withPreview from "../utility/with-preview";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const LandingOfferteTemplate = ({ data, location }) => {
  const {
    title,
    headerType,
    footerType,
    link,
    seo,
    fasce,
    impressionDetail,
    disableVwoAbTesting,
    recensioniVerificateId,
  } = data.page;
  useInitDataLayer(location, impressionDetail);
  useHandleRecaptchaBadge(fasce);
  return (
    <Layout
      headerType={headerType?.value?.[0]}
      footerType={footerType?.value?.[0]}
      location={location}
      recensioniVerificateId={recensioniVerificateId}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path={link}
        robots={seo?.content?.metaRobots?.value?.[0]}
        disableVwoAbTesting={disableVwoAbTesting?.value}
      />
      <div>
        {fasce?.map((fascia, i) => (
          <FasciaLandingOfferte
            fascia={fascia.value}
            key={i}
            location={location}
            title={seo?.content?.htmlTitle?.value || title?.value}
          />
        ))}
      </div>
    </Layout>
  );
};

export default withPreview(LandingOfferteTemplate, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutLandingOfferte(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      headerType {
        value
      }
      footerType {
        value
      }
      impressionDetail {
        value
      }
      link
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      disableVwoAbTesting {
        value
      }
      recensioniVerificateId {
        value
        content {
          recensioniVerificateOn {
            value
          }
        }
      }
      fasce {
        value {
          ...FasciaLandingOfferteFragment
        }
      }
    }
  }
`;

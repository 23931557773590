import React, { useState, useCallback, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { dataLayerPush } from "../../utility/dataLayerUtils";
import classNames from "classnames";
import { Link } from "../link";

const dataLayerEvents = {
  appstore: {
    event: "Area pubblica",
    EventCategory: "Area pubblica",
    EventAction: "Download App",
    EventLabel: "Badge Apple Store - 5.3",
  },
  playstore: {
    event: "Area pubblica",
    EventCategory: "Area pubblica",
    EventAction: "Download App",
    EventLabel: "Badge Google Play - 5.3",
  },
};

const AppBadge = ({ data }) => {
  const { mainTitle, subtitle, appStoreImage, playStoreImage, paddingTop } = data;
  const pushDatalayerEvent = useCallback((e) => {
    dataLayerPush(dataLayerEvents[e.currentTarget.getAttribute("data-store")]);
  }, []);

  const [useIOSLink, setUseIOSLink] = useState(false);
  useEffect(() => {
    setUseIOSLink(/iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream);
  }, []);

  return (
    <section className={classNames("section app-badge", paddingTop?.value && "pt-5")}>
      <div className="container-fluid">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section__title">{mainTitle.value}</h2>
            </div>
          </div>
        )}
        {subtitle?.value && (
          <div className="row">
            <div
              className="col-md-12 text-center app-badge__subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle.value }}
            ></div>
          </div>
        )}
        <div className="row justify-content-center">
          {appStoreImage.node && (
            <div className="col-md-3">
              <div className="text-center app-badge__store app-badge__store--appstore">
                <Link
                  to={
                    appStoreImage?.content?.appStoreLink?.value
                      ? appStoreImage.content.appStoreLink.value
                      : useIOSLink
                      ? "itms-apps://apps.apple.com/it/app/engie-luce-gas-e-servizi/id1019118965?mt=8"
                      : "https://apps.apple.com/it/app/engie-luce-gas-e-servizi/id1019118965?mt=8"
                  }
                  target={useIOSLink ? "_self" : "_blank"}
                  rel="noreferrer"
                  data-store="appstore"
                  onClick={pushDatalayerEvent}
                >
                  <GatsbyImage
                    image={getImage(appStoreImage.node)}
                    alt={appStoreImage.value?.description || ""}
                  />
                </Link>
              </div>
            </div>
          )}
          {playStoreImage.node && (
            <div className="col-md-3">
              <div className="text-center app-badge__store app-badge__store--playstore">
                <Link
                  to={
                    playStoreImage?.content?.playStoreLink?.value
                      ? playStoreImage.content.playStoreLink.value
                      : "https://play.google.com/store/apps/details?id=it.engie.appengie&amp;hl=it&amp;utm_source=website&amp;utm_campaign=app_download&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  }
                  target="_blank"
                  rel="noreferrer"
                  data-store="playstore"
                  onClick={pushDatalayerEvent}
                >
                  <GatsbyImage
                    image={getImage(playStoreImage.node)}
                    alt={playStoreImage.value?.description || ""}
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default withPreview(AppBadge);
export const fragment = graphql`
  fragment JskAppBadgeFragment on LiferayJskAppBadge {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
    }
    subtitle {
      value
    }
    appStoreImage {
      value {
        description
      }
      node {
        gatsbyImageData(width: 240)
      }
      content {
        appStoreLink {
          value
        }
      }
    }
    playStoreImage {
      value {
        description
      }
      node {
        gatsbyImageData(width: 240)
      }
      content {
        playStoreLink {
          value
        }
      }
    }
    paddingTop {
      value
    }
  }
`;

import React, { useState } from "react";
import { graphql } from "gatsby";
import { useForm } from "react-hook-form";
import Input from "../elements/input";
import withPreview from "../../utility/with-preview";
import logo from "../../images/logo-engie-blue.svg";
import Loadable from "@loadable/component";

const Modal = Loadable(() => import("react-overlays/Modal"));

const calculateHash = (password) => {
  return window.crypto.subtle
    .digest("SHA-256", new TextEncoder().encode(password))
    .then((hashBuffer) =>
      Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
    );
};

const OverlayPassword = ({ data }) => {
  const { intro, placeholder, ctaText, passwordHash, errorMessage } = data;

  const [response, setResponse] = useState();
  const { register, handleSubmit } = useForm({ mode: "onChange" });

  const submit = async (values) => {
    const trimmedPassword = values?.codice && values.codice.replace(/ /g, "").toLowerCase();
    const trimmedPasswordHash = await calculateHash(trimmedPassword);
    if (trimmedPasswordHash === passwordHash) {
      setResponse("ok");
    } else {
      setResponse("ko");
    }
  };

  return (
    <Modal show={response !== "ok"} className="flusso-switch-in__modal">
      <div className="flusso-switch-in__modal-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="flusso-switch-in__header text-center">
                <img src={logo} alt="Engie" className="flusso-switch-in__logo" />
              </div>
              <div className="overlay-password flusso-switch-in__wrapper">
                <h2
                  className="flusso-switch-in__title"
                  dangerouslySetInnerHTML={{ __html: intro?.value }}
                ></h2>
                <div className="flusso-switch-in__main-content">
                  {response === "ko" &&
                    (errorMessage?.value ? (
                      <div
                        className="overlay-password__errorMsg"
                        dangerouslySetInnerHTML={{ __html: errorMessage.value }}
                      />
                    ) : (
                      <div className="overlay-password__errorMsg">
                        <p>Ci dispiace, la password inserita non è correta</p>
                      </div>
                    ))}
                  <form className="form" onSubmit={handleSubmit(submit)}>
                    <Input
                      name="codice"
                      register={register}
                      label={placeholder?.value || "Password"}
                      movingLabel={true}
                      iconName="key"
                    />
                    <div className="text-center">
                      <button className="flusso-switch-in__btn" type="submit">
                        {ctaText?.value || "Vai"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withPreview(OverlayPassword);
export const fragment = graphql`
  fragment JskOverlayPasswordFragment on LiferayJskOverlayPassword {
    liferayFields {
      siteId
      articleId
    }
    intro {
      value
    }
    placeholder {
      value
    }
    passwordHash
    ctaText {
      value
    }
    errorMessage {
      value
    }
  }
`;

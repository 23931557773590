import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import WidgetTestuale from "./widget-testuale";

const getComponent = (component) => {
  return {
    LiferayWidgetTestuale: <WidgetTestuale data={component?.value} />,
  }[component?.value?.__typename];
};

const FasciaMulticolonna = ({ data }) => {
  const { columns, components } = data;
  return (
    <div className="container-fluid fascia-multicolonna">
      <div className="row d-flex justify-content-center">
        {components?.map((comp) => (
          <div
            className={`col-md-${12 / Number(columns?.value?.[0] || 2)}`}
            key={comp.value?.liferayFields?.articleId}
          >
            {getComponent(comp)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withPreview(FasciaMulticolonna);
export const fragment = graphql`
  fragment JskFasciaMulticolonnaFragment on LiferayJskFasciaMulticolonna {
    liferayFields {
      siteId
      articleId
    }
    columns {
      value
    }
    components {
      value {
        __typename
        ...WidgetTestualeFragment
      }
    }
  }
`;
